import i18n from "i18next";
import en from "./en.json";
import de from "./de.json";

// Set the default language -- en for english, de for german
const defaultLanguage = "de";
// const defaultLanguage = "en";

i18n.init({
  lng: defaultLanguage,
  resources: {
    en: { translation: en },
    de: { translation: de },
  },
});

export default i18n;
