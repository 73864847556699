import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { AuthProvider } from "../context/AuthContext.js";
import PrivateRoutes from "../utils/PrivateRoutes";

import Login from "../pages/Login/Login";
import Registration from "../pages/Registration/Registration";
import Reports from "../pages/Reports/reports.jsx";
import Orders from "../pages/Orders/Orders";
import Account from "../pages/Account/Account.jsx";
import Products from "../pages/Products/Products.jsx";
import Customers from "../pages/Customers/Customers.jsx";
import User from "../pages/User/User.jsx";

const Routes = () => (
  <IonApp>
    <AuthProvider>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/registration" component={Registration} />
          <Route path="/reports" component={Reports} />
          

          
          <PrivateRoutes>
            <Route path="/orders" component={Orders} />
            <Route path="/account" component={Account} />
            <Route path="/products" component={Products} />
            <Route path="/customers" component={Customers} />
            <Route path="/user" component={User} />
          </PrivateRoutes>
          
        </IonRouterOutlet>
      </IonReactRouter>
    </AuthProvider>
  </IonApp>
);

export default Routes;
