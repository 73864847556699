import React, { useState, useEffect } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonItem,
  IonList,
  IonSelectOption,
  IonSelect,
  IonApp,
  IonContent,
} from "@ionic/react";
import Navigation from "../../components/Navigation";
import SalesContent from "../Reports/SalesMember/sPerformance";
import ProductContent from "../Reports/Product/pPerformance";
import "./reports.css";
import i18n from "../../Translations/i18nConfigs";

const Report = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [reportList, setReportList] = useState("productP");

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <IonApp>
      <IonContent>
        <Navigation />
        <IonGrid>
          <IonRow className="ReportSelectPage">
            <IonCol>
              <IonList>
                <IonItem>
                  <IonSelect
                  value={reportList}
                    aria-label="reportsLists"
                    placeholder="Select Reports"
                    onIonChange={(e) => setReportList(e.detail.value)}
                  >
                    <IonSelectOption value="productP">
                    {i18n.t("report.productperformance")}
                    </IonSelectOption>
                    <IonSelectOption value="salesMP">
                    {i18n.t("report.salesmemberperformance")}
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          {reportList === "productP" && (
            <IonRow className="ReportPCRow">
              <IonCol className="ReportPCCol">
                <ProductContent className="m-0 p-0" />
              </IonCol>
            </IonRow>
          )}
          {reportList === "salesMP" && (
            <IonRow className="ReportPCRow">
              <IonCol className="ReportPCCol">
                <SalesContent className="m-0 p-0" />
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </IonApp>
  );
};

export default Report;
