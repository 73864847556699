import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSearchbar,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonCard,
  IonCardContent,
  IonSelectOption,
  IonSelect,
  IonPopover,
  IonContent,
  IonSpinner,
} from "@ionic/react";
// import Navigation from "../../../components/Navigation";
import "./sPerformances.css";
import {
  downloadOutline,
  ellipsisVertical,
  ellipsisHorizontal,
} from "ionicons/icons";
import configs from "../../../configs";
import i18n from "../../../Translations/i18nConfigs";
import Pagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";


const SalesmemberPerformance = () => {
  const accesstoken = localStorage.getItem("access token");
  const [searchProducts, setsearchProducts] = useState("");
  const [spinner, setSpinner] = useState(false);

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  //pagiantion
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //TotalPage computation
  const totalPages = totalRecords < 5 ? 1 : Math.ceil(totalRecords / 5);

  const [Agent, setAgent] = useState([]);
  useEffect(() => {
    const AgentPerformance = async () => {
      setSpinner(true);
      return axios
        .get(`${configs.base_url}${configs.api_url}sales/?search=${searchProducts}&page=${currentPage}&page_size=4`, {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        })

        .then((response) => {
          const reponseResult = response?.data.results;
          setAgent(reponseResult);
          setTotalRecords(response.data.count);
          console.log(response);
          setSpinner(false);
        });
    };
    AgentPerformance();
  }, []);

  function CSVDownloadList() {
    fetch(`${configs.base_url}${configs.api_url}sales/performance-csv/`, {
      headers: {
        authorization: "Bearer " + accesstoken,
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.headers.get("content-type") === "text/csv") {
          return response.blob();
        } else {
          console.log(response);
          throw new Error("Invalid response");
        }
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Umsatzentwicklung.csv";
        a.click();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function CSVDownloadAgent(ID, Agent) {
    fetch(
      `${configs.base_url}${configs.api_url}sales/performance-csv/` + ID + `/`,
      {
        headers: {
          authorization: "Bearer " + accesstoken,
        },
        withCredentials: true,
      }
    )
      .then((response) => {
        if (response.headers.get("content-type") === "text/csv") {
          return response.blob();
        } else {
          console.log(response);
          throw new Error("Invalid response");
        }
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = Agent + "_Leistung.csv";
        a.click();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="pagecontentinside">
      <IonGrid>
        <IonRow>
          <IonCol className="SMPPagetitle">
            <h3>{i18n.t("salesmemberperformance.title")}</h3>
          </IonCol>
        </IonRow>

        <IonRow >
          <IonCol size-xs="12" size-sm="6" size-md="7" size-lg="5" size-xl="5" className="sortrowSMP">
            <IonItem>
              <IonLabel>{i18n.t("salesmemberperformance.sort")}</IonLabel>
              <IonSelect
                className="filterbtncol"
                placeholder={i18n.t("salesmemberperformance.choose")}
                // onIonChange={(e) => {
                //   setSortOrder(e.detail.value);
                // }}
                // value={sortOrder}
              >
                <IonSelectOption value="ascending" selected>
                {i18n.t("salesmemberperformance.newold")}
                </IonSelectOption>
                <IonSelectOption value="descending">
                {i18n.t("salesmemberperformance.oldnew")}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className="searchordercol">
            <IonSearchbar
              placeholder={i18n.t("salesmemberperformance.searchProduct")}
              className="searchbarorder"
              value={searchProducts}
              onIonChange={(e) => setsearchProducts(e.detail.value)}
            ></IonSearchbar>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className="SMPExportDataCol">
            <IonButton onClick={CSVDownloadList}>
              <IonIcon icon={downloadOutline} style={{ marginRight: "10px" }} />
              {i18n.t("salesmemberperformance.exportdata")}
            </IonButton>
          </IonCol>
        </IonRow>
        {!spinner && (
          <IonRow>
            <IonCol className="cardinfocol">
              {Agent?.filter((user) => {
                if (searchProducts === "") {
                  return user;
                } else if (
                  user.full_name
                    .toLowerCase()
                    .includes(searchProducts.toLowerCase())
                ) {
                  return user;
                }
              }).map((user, index) => (
                <IonCard key={index.toString()} className="marginpaddingzero">
                  <IonCardContent className="infoinsidecards">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" size-md="12" size-xl="4">
                          <h4 className="ion-text-center">
                            <strong>{i18n.t("salesmemberperformance.agentname")} {user.full_name} </strong>
                          </h4>
                        </IonCol>

                        <IonCol size="12" size-md="4" size-xl="4">
                          <h4 className="ion-text-center">
                            <strong>
                            {i18n.t("salesmemberperformance.noassigned")} {user.assigned_po}
                            </strong>
                          </h4>
                        </IonCol>
                        <IonCol size="12" size-md="4" size-xl="3">
                          <h4 className="ion-text-center">
                          {i18n.t("salesmemberperformance.salesconfirmed")} € {user.sales_total_price}
                          </h4>
                        </IonCol>

                        <IonCol size="12" size-md="4" size-xl="1" className="SMPThreeCol">
                          <IonIcon
                            className="actionsbtns"
                            id={`actionsbtns-${index}`}
                            icon={
                              isSmallScreen
                                ? ellipsisHorizontal
                                : ellipsisVertical
                            }
                          />
                          <IonPopover
                            dismiss-on-select="true"
                            alignment="center"
                            id={`popover-${index}`}
                            trigger={`actionsbtns-${index}`}
                            triggerAction="click"
                            side={isSmallScreen ? "top" : "left"}
                          >
                            <IonContent style={{padding:"auto"}}>
                              <IonRow>
                                <IonCol>
                                  <p
                                    onClick={(e) =>
                                      CSVDownloadAgent(user.id, user.full_name)
                                    }
                                  >
                                    {i18n.t("salesmemberperformance.download")}
                                  </p>
                                </IonCol>
                              </IonRow>
                            </IonContent>
                          </IonPopover>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              ))}
            </IonCol>
          </IonRow>
        )}
        {spinner && (
          <IonRow>
            <IonCol className="cardinfocol">
              <IonCard className="marginpaddingzero">
                <IonCardContent className="infoinsidecards">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonSpinner></IonSpinner>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        )}
          <IonRow>
                  <IonCol size-lg="2" className="pagination">
                    <Pagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </IonCol>
                </IonRow>
      </IonGrid>
    </div>
  );
};

export default SalesmemberPerformance;
