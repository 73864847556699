import React from 'react';
import './CustomModal.css'

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="custom-modal">
      <div className="custom-modal-content">
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
