import { useState, useEffect, useContext, useMemo } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonButton,
  IonSearchbar,
  IonIcon,
  IonPopover,
  IonInput,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonButtons,
  IonSpinner,
  IonApp,
} from "@ionic/react";
import Navigation from "../../components/Navigation";
import "./Customers.css";
import {
  ellipsisVertical,
  personCircleOutline,
  ellipsisHorizontal,
} from "ionicons/icons";
import CustomModal from "../../components/CustomModal";
import axios from "axios";
import configs from "../../configs/index";
import i18n from "../../Translations/i18nConfigs";
import AuthContext from "../../context/AuthContext";

import Pagination from "react-responsive-pagination";

const Account = () => {
  const [Customer, setCustomer] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [searchProducts, setsearchProducts] = useState("");
  const { accesstoken } = useContext(AuthContext);

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //TotalPage computation
  const totalPages = totalRecords < 5 ? 1 : Math.ceil(totalRecords / 5);

  useEffect(() => {
    const CustomerList = async () => {
      setSpinner(true);
      return axios
        .get(
          `${configs.base_url}${configs.api_url}transaction/mycustomers/?search=${searchProducts}&page=${currentPage}&page_size=4`,
          {
            headers: {
              authorization: "Bearer " + accesstoken,
            },
          },
        )

        .then((response) => {
          const reponseResult = response?.data?.results;
          setCustomer(reponseResult);
          console.log(reponseResult);
          setTotalRecords(response.data.count);
          setSpinner(false);
        });
    };
    CustomerList();
  }, [searchProducts, accesstoken, currentPage]);

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  const [Customerview, setCustomerview] = useState({
    address: "",
    business_name: "",
    email: "",
    first_name: "",
    last_name: "",
    mob_number: "",
    profile_photo: "",
    orders: [],
  });
  const ViewShow = (
    // address,
    business_name,
    email,
    first_name,
    last_name,
    mob_number,
    profile_photo,
    orders,
  ) => {
    setCustomerview({
      // address: address,
      business_name: business_name,
      email: email,
      first_name: first_name,
      last_name: last_name,
      mob_number: mob_number,
      profile_photo: profile_photo,
      orders: orders,
    });
    window.innerWidth < 768 ? setIsOpen(true) : setIsModalOpen(true);
  };

  console.log(Customerview);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <IonApp>
      <IonContent>
        <Navigation />
        <IonGrid>
          <IonRow>
            <IonCol className="pagetitle">
              <h3>{i18n.t("customers.title")}</h3>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="searchcustomerscol">
              <IonSearchbar
                className="searchbarproduct"
                value={searchProducts}
                onIonChange={(e) => setsearchProducts(e.detail.value)}
              ></IonSearchbar>
            </IonCol>
          </IonRow>

          {!spinner && (
            <IonRow className={isSmallScreen ? "ion-text-center" : ""}>
              <IonCol className="cardcustomersinfocol">
                {Customer?.map((data, index) => (
                  <IonCard
                    key={index.toString()}
                    className="cardcustomersinfocol"
                  >
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol className="profilecol">
                            {data.customer.profile_photo ? (
                              <img
                                src={data.customer.profile_photo}
                                alt="Profile"
                                className="profilecontainer"
                              />
                            ) : (
                              <IonIcon
                                icon={personCircleOutline}
                                className="profilecontainer"
                              />
                            )}
                          </IonCol>
                          <IonCol size-sm="9.5" size-md="5" size-lg="3">
                            <h4>
                              <strong style={{ color: "black" }}>
                                {data.customer.first_name}{" "}
                                {data.customer.last_name}
                              </strong>
                            </h4>
                          </IonCol>
                          <IonCol size-sm="9.5" size-md="5" size-lg="3">
                            <h4>{data.customer.email}</h4>
                          </IonCol>
                          {/* end */}

                          <IonCol>
                            <IonIcon
                              className="threedots"
                              id={`threedots-${index}`}
                              icon={
                                isSmallScreen
                                  ? ellipsisHorizontal
                                  : ellipsisVertical
                              }
                            />
                            <IonPopover
                              dismiss-on-select="true"
                              alignment="center"
                              id={`popover-${index}`}
                              trigger={`threedots-${index}`}
                              triggerAction="click"
                              side={isSmallScreen ? "top" : "left"}
                            >
                              <IonContent class="ion-padding">
                                <IonRow>
                                  <IonCol>
                                    <p
                                      onClick={() => {
                                        ViewShow(
                                          data.customer.business_name,
                                          data.customer.email,
                                          data.customer.first_name,
                                          data.customer.last_name,
                                          data.customer.mob_number,
                                          data.customer.profile_photo,
                                          data.orders,
                                        );
                                      }}
                                    >
                                      {i18n.t("customers.view")}
                                    </p>
                                  </IonCol>
                                </IonRow>
                              </IonContent>
                            </IonPopover>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                ))}

                <IonRow>
                  <IonCol
                    size-lg="2"
                    className="pagination cardcustomersinfocol"
                  >
                    <Pagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          )}
          {spinner && (
            <IonRow className={isSmallScreen ? "ion-text-center" : ""}>
              <IonCol className="cardcustomersinfocol">
                <IonCard className="cardcustomersinfocol">
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonSpinner></IonSpinner>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>

        {/* modal for orderinformations */}
        <CustomModal
          className="modalorderview"
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        >
          <IonRow>
            <IonCol className="WebModalTitle">
              <h3>{i18n.t("customers.details")}</h3>
            </IonCol>

            <IonCol className="XbtnCustomerCol">
              <IonButton onClick={handleCloseModal} className="XbtnCustomer">
                X
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow className="WebModalPictureCol">
            <IonCol>
              <div className="image-upload">
                <label htmlFor="file-input">
                  <div className="image-container">
                    {Customerview.profile_photo ? (
                      <img
                        src={Customerview.profile_photo}
                        alt="Profile"
                        className="profilecontainer"
                      />
                    ) : (
                      <IonIcon
                        icon={personCircleOutline}
                        className="profilecontainer"
                      />
                    )}
                  </div>
                </label>
              </div>
              <IonLabel className="photolabel">
                {i18n.t("customers.photo")}
              </IonLabel>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="marginpadding">
              <IonRow>
                <IonCol className="labelmargins">
                  <IonLabel>{i18n.t("customers.firstName")}</IonLabel>
                </IonCol>
              </IonRow>
              <IonInput className="InputDesign" disabled>
                {" "}
                <p>{Customerview.first_name}</p>
              </IonInput>
            </IonCol>

            <IonCol className="marginpadding">
              <IonRow>
                <IonCol className="labelmargins">
                  <IonLabel>{i18n.t("customers.lastName")}</IonLabel>
                </IonCol>
              </IonRow>
              <IonInput className="InputDesign" disabled>
                {" "}
                <p>{Customerview.last_name}</p>
              </IonInput>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="marginpaddingzero">
              <IonRow>
                <IonCol className="labelmargins">
                  <IonLabel>{i18n.t("customers.businessName")}</IonLabel>
                </IonCol>
              </IonRow>
              <IonInput className="InputDesign" disabled>
                {" "}
                <p>{Customerview.business_name}</p>
              </IonInput>
            </IonCol>
            <IonCol className="marginpaddingzero">
              <IonRow>
                <IonCol className="labelmargins">
                  <IonLabel>{i18n.t("customers.email")}</IonLabel>
                </IonCol>
              </IonRow>
              <IonInput className="InputDesign" disabled>
                {" "}
                <p>{Customerview.email}</p>
              </IonInput>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="marginpaddingzero">
              <IonRow>
                <IonCol className="labelmargins">
                  <IonLabel>{i18n.t("customers.mobileNumber")}</IonLabel>
                </IonCol>
              </IonRow>
              <IonInput className="InputDesign" disabled>
                {" "}
                <p>{Customerview.mob_number}</p>
              </IonInput>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="marginpaddingzero">
              <table>
                <thead>
                  <tr>
                    <th style={{ borderTopLeftRadius: "5px" }}>
                      {i18n.t("customers.poNumber")}
                    </th>
                    <th>{i18n.t("customers.totalAmount")}</th>
                    <th>{i18n.t("customers.dateCreated")}</th>
                    <th style={{ borderTopRightRadius: "5px" }}>
                      {i18n.t("customers.grossTotal")}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {Customerview.orders?.map((data, index) => (
                    <tr key={index.toString()}>
                      <td>#{data.order_no}</td>
                      <td>€ {data.item_total}</td>
                      <td>{data.created_at}</td>
                      <td>€ {data.get_total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </IonCol>
          </IonRow>
        </CustomModal>
        {/* END for modal for orderinformations */}

        {/* modal for android  orderinformations */}

        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <h3>{i18n.t("customers.details")}</h3>
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>
                  {i18n.t("customers.close")}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonRow>
              <IonCol className="CustomerPictureModalMobileCol">
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <div className="image-container">
                      {Customerview.profile_photo ? (
                        <img
                          src={Customerview.profile_photo}
                          alt="Profile"
                          className="profilecontainer"
                        />
                      ) : (
                        <IonIcon
                          icon={personCircleOutline}
                          className="profilecontainer"
                        />
                      )}
                    </div>
                  </label>
                </div>
                <IonLabel className="photolabel">
                  {i18n.t("customers.photo")}
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="labelmargins">
                <IonRow>
                  <IonCol className="labelmargins">
                    <IonLabel>{i18n.t("customers.firstName")}</IonLabel>
                  </IonCol>
                </IonRow>{" "}
                <IonInput className="InputDesign" disabled>
                  {" "}
                  <p>{Customerview.first_name}</p>
                </IonInput>
              </IonCol>

              <IonCol className="labelmargins">
                <IonRow>
                  <IonCol className="labelmargins">
                    <IonLabel>{i18n.t("customers.lastName")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput className="InputDesign" disabled>
                  {" "}
                  <p>{Customerview.last_name}</p>
                </IonInput>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="marginpaddingzero">
                <IonRow>
                  <IonCol className="labelmargins">
                    <IonLabel>{i18n.t("customers.businessName")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput className="InputDesign" disabled>
                  {" "}
                  <p>{Customerview.business_name}</p>
                </IonInput>
              </IonCol>
            </IonRow>

            {/* <IonRow>
              <IonCol className="marginpaddingzero">
                <IonRow>
                  <IonCol className="labelmargins">
                    <IonLabel>Address</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput className="onecolinputs" disabled>
                  {" "}
                  <p>{Customerview.address}</p>
                </IonInput>
              </IonCol>
            </IonRow> */}

            <IonRow>
              <IonCol className="marginpaddingzero">
                <IonRow>
                  <IonCol className="labelmargins">
                    <IonLabel>{i18n.t("customers.email")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput className="InputDesign" disabled>
                  {" "}
                  <p>{Customerview.email}</p>
                </IonInput>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="marginpaddingzero">
                <IonRow>
                  <IonCol className="labelmargins">
                    <IonLabel>{i18n.t("customers.mobileNumber")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput className="InputDesign" disabled>
                  {" "}
                  <p>{Customerview.mob_number}</p>
                </IonInput>
              </IonCol>
            </IonRow>

            <IonRow style={{ overflowX: "auto" }}>
              <IonCol className="marginpaddingzero">
                <table>
                  <thead>
                    <tr>
                      <th style={{ borderTopLeftRadius: "5px" }}>
                        {i18n.t("customers.poNumber")}
                      </th>
                      <th>{i18n.t("customers.totalAmount")}</th>
                      <th>{i18n.t("customers.dateCreated")}</th>
                      <th style={{ borderTopRightRadius: "5px" }}>
                        {i18n.t("customers.grossTotal")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {Customerview.orders?.map((data, index) => (
                      <tr key={index.toString()}>
                        <td>#{data.order_no}</td>
                        <td>€ {data.get_total}</td>
                        <td>{data.created_at}</td>
                        <td>€ {data.item_total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>
        {/*END modal for android  orderinformations */}
      </IonContent>
    </IonApp>
  );
};
export default Account;
