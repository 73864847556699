import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonInput,
  IonPopover,
  IonSpinner,
  IonCheckbox,
  IonLoading,
} from "@ionic/react";
import "./Login.css";
import { useState, useEffect, useCallback } from "react";
import i18n from "../../Translations/i18nConfigs";
import axios from "axios";

import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

import Logo from "../../assets/gm_logo.png";
import configs from "../../configs/index";
// import AuthContext from "../../context/AuthContext";

const Login = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [Validations, setValidations] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const login = useCallback(
    (userCredentials) => {
      setIsLoading(true);
      axios
        .post(`${configs.base_url}${configs.api_url}login/`, userCredentials)
        .then(async (response) => {
          // console.log(response.data);
          localStorage.setItem("access token", response.data.access_token);
          localStorage.setItem("refresh token", response.data.refresh_token);
          localStorage.setItem("pk", response.data.user.pk);
          // console.log(response.data);

          if (rememberMe) {
            await SecureStoragePlugin.set({ key: "em", value: data.email });
            await SecureStoragePlugin.set({
              key: "pw",
              value: data.password,
            });
          }

          setSpinner(false);
          setShowPopover(true);

          window.location.assign("/Orders");
          // history.push("/Orders");
        })
        .catch((error) => {
          console.log(error);
          setValidations(error.response.data);
          setSpinner(false);
          setShowPopover2(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [data.email, data.password, rememberMe],
  );

  const LoginAPI = (e) => {
    setSpinner(true);
    e.preventDefault();

    login(data);
  };

  useEffect(() => {
    const autoLogin = async () => {
      try {
        const { value: email } = await SecureStoragePlugin.get({
          key: "em",
        });
        const { value: password } = await SecureStoragePlugin.get({
          key: "pw",
        });

        if (email && password) {
          console.log("loading...");

          login({ email, password });
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    autoLogin();
  }, [login]);

  //functions for login
  return (
    <>
      <IonPage>
        <IonLoading isOpen={isLoading} message={"Loading.."} />
        <IonContent>
          <IonGrid className="container-grid">
            <IonRow>
              <IonCol
                className="container-col"
                size="12"
                size-sm="3"
                size-md="12"
                size-lg="6"
              >
                <IonRow className="rightcol">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonLabel>SUPPLIER PLATFORM</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonLabel>WILLKOMMEN BEI DER GASTRONOMIA GMBH</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size="12" size-sm="3" size-md="12" size-lg="6">
                <IonRow className="leftcol">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <img
                          src={Logo}
                          alt="Gastronomia Logo"
                          className="logoimg"
                        ></img>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonLabel className="pageheader">
                          {i18n.t("login.title")}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <form onSubmit={LoginAPI}>
                          <IonRow>
                            <IonCol>
                              <IonLabel>{i18n.t("login.email")}</IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonInput
                                placeholder={i18n.t("login.emailPlaceholder")}
                                id="emailtxt"
                                name="email"
                                autocomplete="email"
                                onIonChange={(event) =>
                                  setData({
                                    ...data,
                                    email: event.target.value,
                                  })
                                }
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonLabel>{i18n.t("login.password")}</IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonInput
                                placeholder={i18n.t(
                                  "login.passwordPlaceholder",
                                )}
                                id="passwordtxt"
                                type="password"
                                name="password"
                                onIonChange={(event) =>
                                  setData({
                                    ...data,
                                    password: event.target.value,
                                  })
                                }
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                                padding: "0.5rem",
                              }}
                            >
                              <IonCheckbox
                                checked={rememberMe}
                                name="remember"
                                onIonChange={() =>
                                  setRememberMe((prev) => !prev)
                                }
                              >
                                {i18n.t("login.rememberMe")}
                              </IonCheckbox>

                              <IonLabel>{i18n.t("login.rememberMe")}</IonLabel>
                            </div>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              {!spinner && (
                                <IonButton
                                  expand="block"
                                  className="loginbtn"
                                  type="submit"
                                >
                                  {i18n.t("login.title")}
                                </IonButton>
                              )}
                              {spinner && (
                                <IonButton
                                  expand="block"
                                  className="loginbtn"
                                  type="submit"
                                >
                                  <IonSpinner></IonSpinner>
                                </IonButton>
                              )}
                              <IonPopover
                                isOpen={showPopover}
                                onDidDismiss={() => setShowPopover(false)}
                                className="confirmationbox"
                              >
                                <p className="confirmationmessage">
                                  Login successful!
                                </p>
                              </IonPopover>

                              <IonPopover
                                className="confirmationbox"
                                isOpen={showPopover2}
                                onDidDismiss={() => setShowPopover2(false)}
                              >
                                {Validations &&
                                  Object.keys(Validations).map(
                                    (fieldName, index) => (
                                      <p
                                        className="confirmationmessage"
                                        key={index.toString()}
                                      >
                                        {`${fieldName}: ${Validations[
                                          fieldName
                                        ].join(", ")}`}
                                      </p>
                                    ),
                                  )}
                              </IonPopover>
                            </IonCol>
                          </IonRow>
                        </form>
                      </IonCol>
                    </IonRow>
                    {/* <IonRow className="signuprow">
                      <IonCol>
                        <IonLabel> {i18n.t('login.noAccount')}</IonLabel> {""}
                        <IonLabel className="link" onClick={API}>
                        {i18n.t('login.signup')}
                        </IonLabel>
                      </IonCol>
                    </IonRow> */}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Login;
