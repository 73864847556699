import { Route, Redirect} from 'react-router-dom';
import Orders from "../pages/Orders/Orders";
import Account from "../pages/Account/Account";
import Products from "../pages/Products/Products";
import Customers from "../pages/Customers/Customers";
import User from "../pages/User/User";

const PrivateRoutes = () => {
  const accessToken = localStorage.getItem('access token');
  const isAuthenticated = accessToken !== null;

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Route path="/orders" component={Orders} />
      <Route path="/account" component={Account} />
      <Route path="/products" component={Products} />
      <Route path="/customers" component={Customers} />
      <Route path="/user" component={User} />
    </>
  );
};

export default PrivateRoutes;
