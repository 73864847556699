import React, { useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import {
  globeOutline,
  listOutline,
  bagHandleOutline,
  personOutline,
  personCircleOutline,
  logOutOutline,
  peopleCircleOutline,
} from "ionicons/icons";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import i18n from "../Translations/i18nConfigs";
import logo from "../assets/gm_logo.png";
import "./Navigation.css";
import { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import configs from "../configs/index";



function Example(props) {


  const isActive = (path) => {
    return props.location.pathname === path;
  };

  const activeStyle = {
    color: "white",
    background: "rgba(35, 168, 242, 0.71)",
  };

  const Reports = () => {
    window.location.assign("/reports");

  };
  const Account = () => {
    window.location.assign("/account");

  };
  const Orders = () => {
    window.location.assign("/Orders");

  };
  const Products = () => {
    window.location.assign("/products");

  };
  const Customers = () => {
    window.location.assign("/customers");

  };
  const User = () => {
    window.location.assign("/user");

  };

  const { accesstoken } = useContext(AuthContext);

  const [UserDetails, setUserDetails] = useState([]);

  //functions for logout
  let { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    const User = (e) => {
      axios
        .get(`${configs.base_url}${configs.api_url}userdetail/`, {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        })
        .then((response) => {
          setUserDetails(response.data);
          // console.log(response.data);
          localStorage.setItem("companyID",response.data.company[0].id);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    User();
  }, [accesstoken]);

  return (
    <>
      {/* Start of BurgerMenu */}
      <div className="bugermenu">
        <IonMenu contentId="main-content">
          <IonHeader>
            <IonToolbar>
              <IonTitle>{i18n.t("burgermenu.menu")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonRow>
              <IonCol>
                <img src={logo} className="logocol" />
              </IonCol>
            </IonRow>
            <div className="burgerlistnav">
              <IonList lines="none">
                <IonItem style={isActive("/reports") ? activeStyle : null} onClick={Reports}>
                  <IonIcon
                    icon={globeOutline}
                    style={{ marginRight: "20px" }}
                  />{" "}
                  {i18n.t("burgermenu.reports")}
                </IonItem>
                <IonItem style={isActive("/Orders") ? activeStyle : null} onClick={Orders}>
                  {" "}
                  <IonIcon
                    icon={listOutline}
                    style={{ marginRight: "19px" }}
                    onClick={Orders}
                  />
                  {i18n.t("burgermenu.orders")}
                </IonItem>
                <IonItem style={isActive("/products") ? activeStyle : null} onClick={Products}>
                  {" "}
                  <IonIcon
                    icon={bagHandleOutline}
                    style={{ marginRight: "19px" }}
                  />
                  {i18n.t("burgermenu.products")}
                </IonItem>
                <IonItem style={isActive("/customers") ? activeStyle : null} onClick={Customers}>
                  {" "}
                  <IonIcon
                    icon={personOutline}
                    style={{ marginRight: "19px" }}
                  />
                  {i18n.t("burgermenu.customers")}
                </IonItem>
                <IonItem style={isActive("/account") ? activeStyle : null} onClick={Account}>
                  <IonIcon
                    icon={personCircleOutline}
                    style={{ marginRight: "19px" }}
                    onClick={Account}
                  />
                  {i18n.t("burgermenu.account")}
                </IonItem>

                {/* buttom list */}
                <div className="bottomlistburgernav">
                  <IonItem style={isActive("/user") ? activeStyle : null} onClick={User}>
                    {UserDetails.profile_photo ? (
                      <img
                        src={UserDetails.profile_photo}
                        alt="Profile"
                        className="profilepic"
                      />
                    ) : (
                      <IonIcon
                        icon={peopleCircleOutline}
                        style={{ marginRight: "19px" }}
                      />
                    )}
                    {UserDetails.first_name} {UserDetails.last_name}
                  </IonItem>
                  <IonItem onClick={logoutUser}>
                    <IonIcon
                      icon={logOutOutline}
                      style={{ marginRight: "19px" }}
                    />
                    {i18n.t("burgermenu.logout")}
                  </IonItem>
                </div>
              </IonList>
            </div>
          </IonContent>
        </IonMenu>
        {/* End of BurgerMenu */}

        {/* Start of SideMenu */}
        <div className="ion-page" id="main-content">
          <ion-header
            style={{
              "--ion-background-color":
                "linear-gradient(112.63deg, #0083D0 8.18%, #23A8F2 93.79%)",
            }}
          >
            <ion-toolbar>
              <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
              </IonButtons>
            </ion-toolbar>
          </ion-header>
        </div>
      </div>

      <div className="staticnavbar">
        <IonPage className="staticnavbar">
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <img src={logo} className="logocol" />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className="listnav">
                    <IonList lines="none">
                      <IonItem style={isActive("/reports") ? activeStyle : null} onClick={Reports}>
                        <IonIcon
                          icon={globeOutline}
                          style={{ color: "#fff", marginRight: "20px" }}
                        />{" "}
                        {i18n.t("sidebar.reports")}
                      </IonItem>
                      <IonItem style={isActive("/Orders") ? activeStyle : null} onClick={Orders}>
                        {" "}
                        <IonIcon
                          icon={listOutline}
                          style={{ color: "#fff", marginRight: "19px" }}
                          onClick={Orders}
                        />
                        {i18n.t("sidebar.orders")}
                      </IonItem>
                      <IonItem style={isActive("/products") ? activeStyle : null} onClick={Products}>
                        {" "}
                        <IonIcon
                          icon={bagHandleOutline}
                          style={{ color: "#fff", marginRight: "19px" }}
                        />
                        {i18n.t("sidebar.products")}
                      </IonItem>
                      <IonItem style={isActive("/customers") ? activeStyle : null} onClick={Customers}>
                        {" "}
                        <IonIcon
                          icon={personOutline}
                          style={{ color: "#fff", marginRight: "19px" }}
                        />
                        {i18n.t("sidebar.customers")}
                      </IonItem>
                      <IonItem style={isActive("/account") ? activeStyle : null} onClick={Account}>
                        <IonIcon
                          icon={personCircleOutline}
                          style={{ color: "#fff", marginRight: "19px" }}
                          onClick={Account}
                        />
                        {i18n.t("sidebar.account")}
                      </IonItem>

                      {/* buttom list */}
                      <div className="bottomlistnav">
                        <IonItem style={isActive("/user") ? activeStyle : null} onClick={User}>
                          {UserDetails.profile_photo ? (
                            <img
                              src={UserDetails.profile_photo}
                              alt="Profile"
                              className="profilepic"
                            />
                          ) : (
                            <IonIcon
                              icon={peopleCircleOutline}
                              style={{ marginRight: "19px" }}
                            />
                          )}
                          {UserDetails.first_name} {UserDetails.last_name}
                        </IonItem>
                        <IonItem onClick={logoutUser}>
                          <IonIcon
                            icon={logOutOutline}
                            style={{ color: "#fff", marginRight: "19px" }}
                          />
                          {i18n.t("sidebar.logout")}
                        </IonItem>
                      </div>
                    </IonList>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </div>
      {/* End of SideMenu */}
    </>
  );
}
export default withRouter(Example);
