import { useState, useEffect, useContext } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonButton,
  IonSearchbar,
  IonIcon,
  IonPopover,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonContent,
  IonSpinner,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonCheckbox,
  IonApp,
} from "@ionic/react";
import Navigation from "../../components/Navigation";
import "./Products.css";
import {
  ellipsisVertical,
  cameraOutline,
  ellipsisHorizontal,
} from "ionicons/icons";
import i18n from "../../Translations/i18nConfigs";
import CustomModal from "../../components/CustomModal";
import axios from "axios";
import configs from "../../configs/index";
import AuthContext from "../../context/AuthContext";
import Pagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";

const Account = () => {
  const [Products, setProducts] = useState([]);
  const [Companies, setCompanies] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [searchProducts, setsearchProducts] = useState("");
  const [spinner, setSpinner] = useState(false);
  const { accesstoken } = useContext(AuthContext);

  //pagiantion
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //TotalPage computation
  const totalPages = totalRecords < 5 ? 1 : Math.ceil(totalRecords / 5);

  // console.log(Products);

  useEffect(() => {
    const Product = async () => {
      setSpinner(true);
      return axios
        .get(
          `${configs.base_url}${configs.api_url}products/myproducts/?search=${searchProducts}&page=${currentPage}&page_size=4`,
          {
            headers: {
              authorization: "Bearer " + accesstoken,
            },
          }
        )

        .then((response) => {
          const reponseResult = response?.data.results;
          setProducts(reponseResult);
          setTotalRecords(response.data.count);
          // console.log(response);
          setSpinner(false);
        });
    };

    const Companies = async () => {
      return axios
        .get(`${configs.base_url}${configs.api_url}users/company/list/`, {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        })

        .then((response) => {
          // console.log(response);
          setCompanies(response?.data);
        });
    };

    const Categories = async () => {
      return axios
        .get(`${configs.base_url}${configs.api_url}category/list/`, {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        })

        .then((response) => {
          // console.log(response?.data);
          setCategories(response?.data);
        });
    };

    Companies();
    Product();
    Categories();
  }, [accesstoken, searchProducts,currentPage]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [Validations, setValidations] = useState([]);
  const [showPopover3, setShowPopover3] = useState(false);
  const [showPopover4, setShowPopover4] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
  };

  const [createProduct, setcreateProduct] = useState({
    name: "",
    product_id: "",
    price: 0,
    units: "",
    category: "",
    viewers: [],
    description: "",
    sale: false,
  });
  // console.log(createProduct);
  const [selectedFile, setSelectedFile] = useState();
  const [loadingforcreate, setloadingforcreate] = useState(false);
  const formData = new FormData();
  formData.append("name", createProduct.name);
  formData.append("product_id", createProduct.product_id);
  formData.append("price", createProduct.price);
  formData.append("units", createProduct.units);
  formData.append("category", createProduct.category);
  createProduct.viewers.forEach((viewer) => {
    formData.append("viewers", viewer);
  });
  formData.append("description", createProduct.description);
  formData.append("sale", createProduct.sale);
  selectedFile && formData.append("image", selectedFile, selectedFile.name);
  // console.log(formData);
  async function CreateProduct(event) {
    event.preventDefault();
    setloadingforcreate(true);
    axios
      .post(`${configs.base_url}${configs.api_url}products/create/`, formData, {
        headers: {
          Authorization: "Bearer " + accesstoken,
        },
      })
      .then((response) => {
        console.log(response);
        setShowPopover(true);

        setTimeout(() => {
          window.location.reload();
          setloadingforcreate(false);
        }, 2000);
        // handle success
      })
      .catch((error) => {
        console.log(error);
        setValidations(error.response.data);
        setShowPopover2(true);
        setloadingforcreate(false);
        setTimeout(() => {}, 2000);
      });
  }
  async function DeleteProduct(productid) {
    axios
      .delete(
        `${configs.base_url}${configs.api_url}products/myproducts/` +
          productid +
          "/",
        {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        }
      )
      .then((response) => {
        // console.log(response);

        window.location.reload();
        // handle success
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  const [Productview, setProductview] = useState({
    name: "",
    product_id: "",
    price: 0,
    units: "",
    category: "",
    viewers: [],
    description: "",
    image: "",
    owner: "",
    sale: null,
  });

  // console.log(Productview);
  const ViewShow = (
    name,
    product_id,
    price,
    units,
    category,
    viewers,
    description,
    image,
    ownerid,
    sale
  ) => {
    setProductview({
      name: name,
      product_id: product_id,
      price: price,
      units: units,
      category: category,
      viewers: viewers.map((viewer) => viewer.id),
      description: description,
      image: image,
      owner: ownerid,
      sale: sale,
    });
    window.innerWidth < 768 ? setIsOpen2(true) : handleOpenModal2(true);
  };

  // console.log(Productview);
  const [editID, seteditID] = useState();
  const [loadingforupdate, setloadingforupdate] = useState(false);
  const UpdateformData = new FormData();
  UpdateformData.append("name", Productview.name);
  UpdateformData.append("product_id", Productview.product_id);
  UpdateformData.append("price", Productview.price);
  UpdateformData.append("units", Productview.units);
  UpdateformData.append("category", Productview.category);
  UpdateformData.append("description", Productview.description);
  Productview.viewers?.forEach((viewer) => {
    UpdateformData.append("viewers", viewer);
  });
  selectedFile &&
    UpdateformData.append("image", selectedFile, selectedFile.name);
  UpdateformData.append("owner", Productview.owner);
  UpdateformData.append("sale", Productview.sale);
  // console.log(UpdateformData);
  async function UpdateData(event) {
    event.preventDefault();
    setloadingforupdate(true);
    axios
      .patch(
        `${configs.base_url}${configs.api_url}products/myproducts/` +
          editID +
          "/",
        UpdateformData,
        {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setShowPopover(true);

        setTimeout(() => {
          window.location.reload();
          setloadingforupdate(false);
        }, 2000);
        // handle success
      })
      .catch((error) => {
        console.log(error);
        setShowPopover2(true);

        setTimeout(() => {
          setloadingforupdate(false);
        }, 2000);
      });
  }

  // modal for android
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  return (
    <IonApp>
      <IonContent>
        <Navigation />
        <IonGrid>
          <IonRow>
            <IonCol
              className="pagetitle"
              size="12"
              size-sm="3"
              size-md="5"
              size-lg="3"
            >
              <h3>{i18n.t("product.title")}</h3>
            </IonCol>
            <IonCol
              size="12"
              size-sm="3"
              size-md="6"
              size-lg="6"
              className="createprdctbtncol"
            >
              <IonButton
                expand="block"
                onClick={
                  window.innerWidth < 768
                    ? () => setIsOpen(true)
                    : handleOpenModal
                }
              >
                {i18n.t("product.createproduct")}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="searchscol">
              <IonSearchbar
                placeholder={i18n.t("product.searchProduct")}
                value={searchProducts}
                onIonChange={(e) => setsearchProducts(e.detail.value)}
              ></IonSearchbar>
            </IonCol>
          </IonRow>

          {!spinner && (
            <IonRow>
              <IonCol className="productlistcol">
                {Products?.map((item, index) => (
                  <IonCard key={index.toString()}>
                    <IonCardContent className="cardcontents">
                      <IonGrid>
                        <IonRow>
                          <IonCol
                            size="6"
                            size-sm="3"
                            size-md="3"
                            size-lg="2"
                            className="txtcol"
                          >
                            <h4>
                              <strong>{item.name}</strong>
                            </h4>
                          </IonCol>
                          <IonCol
                            size="6"
                            size-sm="3"
                            size-md="3"
                            size-lg="2"
                            className="txtcol"
                          >
                            <h4>
                              <strong>{item.product_id}</strong>
                            </h4>
                          </IonCol>
                          <IonCol
                            size="6"
                            size-sm="3"
                            size-md="3"
                            size-lg="2"
                            className="txtcol"
                          >
                            <h4>
                              {i18n.t("product.unit")}{" "}
                              {item.units === "kg"
                                ? `${i18n.t("product.kilo")}`
                                : item.units === "l"
                                ? `${i18n.t("product.liter")}`
                                : item.units === "pc"
                                ? `${i18n.t("product.piece")}`
                                : item.units === "box"
                                ? `${i18n.t("product.box")}`
                                : item.units === "dozen"
                                ? `${i18n.t("product.dozen")}`
                                : item.units}
                            </h4>
                          </IonCol>
                          <IonCol
                            size="6"
                            size-sm="3"
                            size-md="3"
                            size-lg="2"
                            className="txtcol"
                          >
                            <h4>
                              {i18n.t("product.price")} €{item.price}
                            </h4>
                          </IonCol>
                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="6"
                            size-lg="2"
                            className="catcol"
                          >
                            <IonButton expand="block">
                              {item.category === "meat"
                                ? `${i18n.t("product.meat")}`
                                : item.category === "condiments"
                                ? `${i18n.t("product.condiments")}`
                                : item.category === "snacks"
                                ? `${i18n.t("product.snacks")}`
                                : item.category === "vegetables"
                                ? `${i18n.t("product.vegetables")}`
                                : item.category === "fruit"
                                ? `${i18n.t("product.fruit")}`
                                : `${i18n.t("product.other")}`}
                            </IonButton>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="6"
                            size-lg="2"
                            className="dotscol"
                          >
                            <IonIcon
                              className="dotsbtn"
                              id={`threedots-${index}`}
                              icon={
                                isSmallScreen
                                  ? ellipsisHorizontal
                                  : ellipsisVertical
                              }
                            />
                            <IonPopover
                              dismiss-on-select="true"
                              alignment="center"
                              id={`popover-${index}`}
                              trigger={`threedots-${index}`}
                              triggerAction="click"
                              side={isSmallScreen ? "top" : "left"}
                            >
                              <IonContent className="popovercontent">
                                <IonRow>
                                  <IonCol>
                                    <p
                                      onClick={() => {
                                        ViewShow(
                                          item.name,
                                          item.product_id,
                                          item.price,
                                          item.units,
                                          item.category.id,
                                          item.viewers,
                                          item.description,
                                          item.image,
                                          item.owner.id,
                                          item.sale
                                        );
                                        seteditID(item.id);
                                      }}
                                    >
                                      {i18n.t("product.edit")}
                                    </p>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol>
                                    <p
                                      onClick={() => {
                                        DeleteProduct(item.id);
                                      }}
                                    >
                                      {i18n.t("product.delete")}
                                    </p>
                                  </IonCol>
                                </IonRow>
                              </IonContent>
                            </IonPopover>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                ))}

                <IonRow>
                  <IonCol size-lg="2" className="pagination">
                    <Pagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          )}
          {spinner && (
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonSpinner></IonSpinner>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>

        {/* Modal for creation of Products */}
        <form onSubmit={CreateProduct}>
          <CustomModal
            className="modalselfs"
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          >
            <IonRow>
              <IonCol>
                <h3>{i18n.t("product.createproduct")}</h3>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ProductCreatePictureCol">
                <div className="imageupload">
                  <label htmlFor="file-input">
                    <div className="imagecontainer">
                      {selectedFile && (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                        />
                      )}
                      {!selectedFile && (
                        <IonIcon
                          icon={cameraOutline}
                          className="iconweb"
                        ></IonIcon>
                      )}
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/jpeg, image/png, image/svg"
                    onChange={(event) => {
                      setSelectedFile(event.target.files[0]);
                    }}
                  />
                </div>
                <IonLabel className="ProductCreatePictureLabel">
                  {i18n.t("product.photo")}
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productname")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductname")}
                  className="inputboxweb"
                  value={createProduct.name}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      name: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productid")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductid")}
                  className="inputboxweb"
                  value={createProduct.product_id}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      product_id: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productdescription")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductdescription")}
                  className="inputboxweb"
                  value={createProduct.description}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      description: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.unit")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonSelect
                      className="inputselectweb"
                      placeholder={i18n.t("product.placeunit")}
                      value={createProduct.units}
                      onIonChange={(event) =>
                        setcreateProduct({
                          ...createProduct,
                          units: event.target.value,
                        })
                      }
                    >
                      <IonSelectOption value="BLT">BLT</IonSelectOption>
                      <IonSelectOption value="BND">BND</IonSelectOption>
                      <IonSelectOption value="BTL">BTL</IonSelectOption>
                      <IonSelectOption value="DOS">DOS</IonSelectOption>
                      <IonSelectOption value="EIM">EIM</IonSelectOption>
                      <IonSelectOption value="FL">FL</IonSelectOption>
                      <IonSelectOption value="GLA">GLA</IonSelectOption>
                      <IonSelectOption value="KA">KA</IonSelectOption>
                      <IonSelectOption value="KAR">KAR</IonSelectOption>
                      <IonSelectOption value="KG">KG</IonSelectOption>
                      <IonSelectOption value="KRT">KRT</IonSelectOption>
                      <IonSelectOption value="PCK">PCK</IonSelectOption>
                      <IonSelectOption value="SCH">SCH</IonSelectOption>
                      <IonSelectOption value="SCK">SCK</IonSelectOption>
                      <IonSelectOption value="ST">ST</IonSelectOption>
                      <IonSelectOption value="STK">STK</IonSelectOption>
                      <IonSelectOption value="TUB">TUB</IonSelectOption>
                    </IonSelect>
                  </IonCol>
                </IonRow>
              </IonCol>

              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.price")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder="Enter the Price"
                  className="inputselectweb2"
                  type="number"
                  value={createProduct.price}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      price: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.category")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecategory")}
                  className="inputselectweb"
                  value={createProduct.category}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      category: event.target.value,
                    })
                  }
                >
                  {Categories?.map((data, index) => (
                    <IonSelectOption key={index} value={data.id}>
                      {data.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.companies")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecompanies")}
                  value={createProduct.viewers}
                  className="inputselectweb"
                  multiple={true}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      viewers: event.target.value,
                    })
                  }
                >
                  {Companies?.map((company, index) => (
                    <IonSelectOption key={index} value={company.id}>
                      {company.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol size="1">
                    <IonCheckbox
                      labelPlacement="end"
                      onIonChange={(event) =>
                        setcreateProduct({
                          ...createProduct,
                          sale: event.target.checked ? true : false,
                        })
                      }
                    ></IonCheckbox>
                  </IonCol>

                  <IonCol size="2" className="salelabel2">
                    <IonLabel>{i18n.t("product.sale")}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonButton expand="block" onClick={handleCloseModal}>
                  {i18n.t("product.cancel")}
                </IonButton>
              </IonCol>

              <IonCol className="modalcontentinputweb">
                {loadingforcreate ? (
                  <IonButton expand="block" type="submit">
                    <IonSpinner></IonSpinner>
                  </IonButton>
                ) : (
                  <IonButton expand="block" type="submit">
                    {i18n.t("product.create")}
                  </IonButton>
                )}

                <IonPopover
                  className="confirmationboxs"
                  isOpen={showPopover}
                  onDidDismiss={() => setShowPopover(false)}
                >
                  <p className="confirmationmessages">
                    Product Creation successful!<br></br> Wait for a moment
                    Redirecting to Products.....
                  </p>
                </IonPopover>

                <IonPopover
                  className="confirmationboxs"
                  isOpen={showPopover2}
                  onDidDismiss={() => setShowPopover2(false)}
                >
                  <p className="confirmationmessages">
                    {JSON.stringify(Validations)}
                  </p>
                </IonPopover>
              </IonCol>
            </IonRow>
          </CustomModal>
        </form>
        {/* end of Modal */}

        {/* Modal for update of Products */}
        <form onSubmit={UpdateData}>
          <CustomModal
            className="modalself"
            isOpen={isModalOpen2}
            onClose={handleCloseModal2}
          >
            <IonRow>
              <IonCol>
                <h3>{i18n.t("product.editproduct")}</h3>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ProductCreatePictureCol">
                <div className="imageupload">
                  <label htmlFor="file-input">
                    <div className="imagecontainer">
                      {selectedFile ? (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                        />
                      ) : (
                        <img src={Productview.image} alt="Default" />
                      )}
                      <IonIcon
                        icon={cameraOutline}
                        className="iconweb"
                      ></IonIcon>
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/jpeg, image/png, image/svg"
                    onChange={(event) => setSelectedFile(event.target.files[0])}
                  />
                </div>
                <IonLabel className="ProductCreatePictureLabel">
                  {i18n.t("product.photo")}
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productname")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductname")}
                  className="inputboxweb"
                  value={Productview.name}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      name: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productid")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductid")}
                  className="inputboxweb"
                  value={Productview.product_id}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      product_id: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productdescription")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductdescription")}
                  className="inputboxweb"
                  value={Productview.description}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      description: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol className="modalcontentinputweb">
                    <IonLabel>{i18n.t("product.unit")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonSelect
                      placeholder={i18n.t("product.placeunit")}
                      className="inputselectweb"
                      value={Productview.units}
                      onIonChange={(event) =>
                        setProductview({
                          ...Productview,
                          units: event.target.value,
                        })
                      }
                    >
                      <IonSelectOption value="BLT">BLT</IonSelectOption>
                      <IonSelectOption value="BND">BND</IonSelectOption>
                      <IonSelectOption value="BTL">BTL</IonSelectOption>
                      <IonSelectOption value="DOS">DOS</IonSelectOption>
                      <IonSelectOption value="EIM">EIM</IonSelectOption>
                      <IonSelectOption value="FL">FL</IonSelectOption>
                      <IonSelectOption value="GLA">GLA</IonSelectOption>
                      <IonSelectOption value="KA">KA</IonSelectOption>
                      <IonSelectOption value="KAR">KAR</IonSelectOption>
                      <IonSelectOption value="KG">KG</IonSelectOption>
                      <IonSelectOption value="KRT">KRT</IonSelectOption>
                      <IonSelectOption value="PCK">PCK</IonSelectOption>
                      <IonSelectOption value="SCH">SCH</IonSelectOption>
                      <IonSelectOption value="SCK">SCK</IonSelectOption>
                      <IonSelectOption value="ST">ST</IonSelectOption>
                      <IonSelectOption value="STK">STK</IonSelectOption>
                      <IonSelectOption value="TUB">TUB</IonSelectOption>
                    </IonSelect>
                  </IonCol>
                </IonRow>
              </IonCol>

              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.price")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder="Enter the Price"
                  className="inputselectweb2"
                  type="number"
                  value={Productview.price}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      price: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.category")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecategory")}
                  className="inputselectweb"
                  value={Productview.category}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      category: event.target.value,
                    })
                  }
                >
                  {Categories?.map((data, index) => (
                    <IonSelectOption key={index} value={data.id}>
                      {data.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.companies")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecompanies")}
                  className="inputselectweb"
                  value={Productview.viewers}
                  multiple={true}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      viewers: event.target.value,
                    })
                  }
                >
                  {Companies?.map((company, index) => (
                    <IonSelectOption key={index} value={company.id}>
                      {company.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="1" className="modalcontentinputweb">
                <IonCheckbox
                  labelPlacement="end"
                  checked={Productview.sale}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      sale: event.target.checked,
                    })
                  }
                ></IonCheckbox>
              </IonCol>

              <IonCol size="2" className="modalcontentinputweb">
                <IonLabel>{i18n.t("product.sale")}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinputweb">
                <IonButton expand="block" onClick={handleCloseModal2}>
                  {i18n.t("product.cancel")}
                </IonButton>
              </IonCol>

              <IonCol className="modalcontentinputweb">
                {loadingforupdate ? (
                  <IonButton expand="block" type="submit">
                    <IonSpinner />
                  </IonButton>
                ) : (
                  <IonButton expand="block" type="submit">
                    {i18n.t("product.update")}
                  </IonButton>
                )}
                <IonPopover
                  className="confirmationboxs"
                  isOpen={showPopover}
                  onDidDismiss={() => setShowPopover(false)}
                >
                  <p className="confirmationmessages">
                    Product Creation successful!
                    <br></br> Wait for a moment Redirecting to Products.....
                  </p>
                </IonPopover>

                <IonPopover
                  className="confirmationboxs"
                  isOpen={showPopover2}
                  onDidDismiss={() => setShowPopover2(false)}
                >
                  <p className="confirmationmessages">
                    {JSON.stringify(Validations)}
                  </p>
                </IonPopover>
              </IonCol>
            </IonRow>
          </CustomModal>
        </form>
        {/* end of Modal */}

        {/* modal for ios/android */}
        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{i18n.t("product.createproduct")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonRow>
              <IonCol className="picturecol">
                <div className="imageupload">
                  <label htmlFor="file-input">
                    <div className="imagecontainer">
                      {selectedFile && (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                        />
                      )}
                      <IonIcon icon={cameraOutline}></IonIcon>
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/jpeg, image/png, image/svg"
                    onChange={(event) => {
                      setSelectedFile(event.target.files[0]);
                    }}
                  />
                </div>
                <IonLabel className="photo-label">
                  {" "}
                  {i18n.t("product.photo")}
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productname")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductname")}
                  className="inputbox"
                  value={createProduct.name}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      name: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productid")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductid")}
                  className="inputbox"
                  value={createProduct.product_id}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      product_id: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productdescription")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductdescription")}
                  className="inputbox"
                  value={createProduct.description}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      description: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.unit")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonSelect
                      placeholder={i18n.t("product.placeunit")}
                      className="inputselect"
                      value={createProduct.units}
                      onIonChange={(event) =>
                        setcreateProduct({
                          ...createProduct,
                          units: event.target.value,
                        })
                      }
                    >
                      <IonSelectOption value="BLT">BLT</IonSelectOption>
                      <IonSelectOption value="BND">BND</IonSelectOption>
                      <IonSelectOption value="BTL">BTL</IonSelectOption>
                      <IonSelectOption value="DOS">DOS</IonSelectOption>
                      <IonSelectOption value="EIM">EIM</IonSelectOption>
                      <IonSelectOption value="FL">FL</IonSelectOption>
                      <IonSelectOption value="GLA">GLA</IonSelectOption>
                      <IonSelectOption value="KA">KA</IonSelectOption>
                      <IonSelectOption value="KAR">KAR</IonSelectOption>
                      <IonSelectOption value="KG">KG</IonSelectOption>
                      <IonSelectOption value="KRT">KRT</IonSelectOption>
                      <IonSelectOption value="PCK">PCK</IonSelectOption>
                      <IonSelectOption value="SCH">SCH</IonSelectOption>
                      <IonSelectOption value="SCK">SCK</IonSelectOption>
                      <IonSelectOption value="ST">ST</IonSelectOption>
                      <IonSelectOption value="STK">STK</IonSelectOption>
                      <IonSelectOption value="TUB">TUB</IonSelectOption>
                    </IonSelect>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.price")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder="Enter the Price"
                  className="inputbox"
                  type="number"
                  value={createProduct.price}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      price: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.category")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecategory")}
                  className="inputselect"
                  value={createProduct.category}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      category: event.target.value,
                    })
                  }
                >
                  {Categories?.map((data, index) => (
                    <IonSelectOption key={index} value={data.id}>
                      {data.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.companies")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecompanies")}
                  className="productnameinput"
                  value={createProduct.viewers}
                  multiple={true}
                  onIonChange={(event) =>
                    setcreateProduct({
                      ...createProduct,
                      viewers: event.target.value,
                    })
                  }
                >
                  {Companies?.map((company, index) => (
                    <IonSelectOption key={index} value={company.id}>
                      {company.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentticbox">
                <IonRow>
                  <IonCol size="1">
                    <IonCheckbox
                      labelPlacement="end"
                      onIonChange={(event) =>
                        setcreateProduct({
                          ...createProduct,
                          sale: event.target.checked ? true : false,
                        })
                      }
                    ></IonCheckbox>
                  </IonCol>
                  <IonCol size="3" className="salelabel">
                    <IonLabel>{i18n.t("product.sale")}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6" className="btncol">
                <IonButton expand="block" onClick={() => setIsOpen(false)}>
                  {i18n.t("product.cancel")}
                </IonButton>
              </IonCol>

              <IonCol size="6" className="btncol">
                {loadingforcreate ? (
                  <IonButton expand="block">
                    <IonSpinner name="crescent" />
                  </IonButton>
                ) : (
                  <IonButton expand="block" onClick={CreateProduct}>
                    {i18n.t("product.create")}
                  </IonButton>
                )}
                <IonPopover
                  className="confirmationboxs"
                  isOpen={showPopover}
                  onDidDismiss={() => setShowPopover(false)}
                >
                  <p className="confirmationmessages">
                    Product Creation successful!<br></br> Wait for a moment
                    Redirecting to Products.....
                  </p>
                </IonPopover>

                <IonPopover
                  className="confirmationboxs"
                  isOpen={showPopover2}
                  onDidDismiss={() => setShowPopover2(false)}
                >
                  <p className="confirmationmessages">
                    {JSON.stringify(Validations)}
                  </p>
                </IonPopover>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>
        {/* end of ios Modal */}

        {/* modal2edit for ios/android */}
        <IonModal isOpen={isOpen2}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{i18n.t("product.editproduct")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonRow>
              <IonCol className="picturecol">
                <div className="imageupload">
                  <label htmlFor="file-input">
                    <div className="imagecontainer">
                      {selectedFile ? (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                        />
                      ) : (
                        <img src={Productview.image} />
                      )}
                      <IonIcon icon={cameraOutline}></IonIcon>
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/jpeg, image/png, image/svg"
                    onChange={(event) => {
                      setSelectedFile(event.target.files[0]);
                    }}
                  />
                </div>
                <IonLabel className="photo-label">
                  {" "}
                  {i18n.t("product.photo")}
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productname")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductname")}
                  className="inputbox"
                  value={Productview.name}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      name: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productid")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductid")}
                  className="inputbox"
                  value={Productview.product_id}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      product_id: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.productdescription")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder={i18n.t("product.placeproductdescription")}
                  className="inputbox"
                  value={Productview.description}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      description: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.unit")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonSelect
                      placeholder={i18n.t("product.placeunit")}
                      className="inputselect"
                      value={Productview.units}
                      onIonChange={(event) =>
                        setProductview({
                          ...Productview,
                          units: event.target.value,
                        })
                      }
                    >
                      <IonSelectOption value="BLT">BLT</IonSelectOption>
                      <IonSelectOption value="BND">BND</IonSelectOption>
                      <IonSelectOption value="BTL">BTL</IonSelectOption>
                      <IonSelectOption value="DOS">DOS</IonSelectOption>
                      <IonSelectOption value="EIM">EIM</IonSelectOption>
                      <IonSelectOption value="FL">FL</IonSelectOption>
                      <IonSelectOption value="GLA">GLA</IonSelectOption>
                      <IonSelectOption value="KA">KA</IonSelectOption>
                      <IonSelectOption value="KAR">KAR</IonSelectOption>
                      <IonSelectOption value="KG">KG</IonSelectOption>
                      <IonSelectOption value="KRT">KRT</IonSelectOption>
                      <IonSelectOption value="PCK">PCK</IonSelectOption>
                      <IonSelectOption value="SCH">SCH</IonSelectOption>
                      <IonSelectOption value="SCK">SCK</IonSelectOption>
                      <IonSelectOption value="ST">ST</IonSelectOption>
                      <IonSelectOption value="STK">STK</IonSelectOption>
                      <IonSelectOption value="TUB">TUB</IonSelectOption>
                    </IonSelect>
                  </IonCol>
                </IonRow>
              </IonCol>

              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.price")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonInput
                  placeholder="Enter the Price"
                  className="inputbox"
                  type="number"
                  value={Productview.price}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      price: event.target.value,
                    })
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.category")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecategory")}
                  className="inputselect"
                  value={Productview.category}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      category: event.target.value,
                    })
                  }
                >
                  {Categories?.map((data, index) => (
                    <IonSelectOption key={index} value={data.id}>
                      {data.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentinput">
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("product.companies")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonSelect
                  placeholder={i18n.t("product.placecompanies")}
                  className="productnameinput"
                  value={Productview.viewers}
                  multiple={true}
                  onIonChange={(event) =>
                    setProductview({
                      ...Productview,
                      viewers: event.target.value,
                    })
                  }
                >
                  {Companies?.map((company, index) => (
                    <IonSelectOption key={index} value={company.id}>
                      {company.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="modalcontentticbox">
                <IonRow>
                  <IonCol size="1">
                    <IonCheckbox
                      labelPlacement="end"
                      checked={Productview.sale}
                      onIonChange={(event) =>
                        setProductview({
                          ...Productview,
                          sale: event.target.checked,
                        })
                      }
                    ></IonCheckbox>
                  </IonCol>
                  <IonCol size="3" className="salelabel">
                    <IonLabel>{i18n.t("product.sale")}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6" className="btncol">
                <IonButton expand="block" onClick={() => setIsOpen2(false)}>
                  {i18n.t("product.cancel")}
                </IonButton>
              </IonCol>

              <IonCol size="6" className="btncol">
                {loadingforupdate ? (
                  <IonButton expand="block">
                    <IonSpinner name="crescent" />
                  </IonButton>
                ) : (
                  <IonButton expand="block" onClick={UpdateData}>
                    {i18n.t("product.update")}
                  </IonButton>
                )}
                <IonPopover
                  className="confirmationpop"
                  isOpen={showPopover}
                  onDidDismiss={() => setShowPopover(false)}
                >
                  <p className="confirmation">
                    Product Update successful!<br></br> Wait for a moment
                    Redirecting to Products.....
                  </p>
                </IonPopover>

                <IonPopover
                  className="confirmationpop"
                  isOpen={showPopover2}
                  onDidDismiss={() => setShowPopover2(false)}
                >
                  <p className="confirmation">{Validations}</p>
                </IonPopover>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>
        {/* end of modal2edit ios/android */}
      </IonContent>
    </IonApp>
  );
};
export default Account;
