import { useState, useEffect, useContext } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  IonLabel,
  IonIcon,
  IonButton,
  IonSpinner,
  IonPopover,
  IonApp,
  IonContent,
} from "@ionic/react";
import Navigation from "../../components/Navigation";
import "./User.css";
import { cameraOutline } from "ionicons/icons";
import axios from "axios";
import configs from "../../configs/index";
import i18n from "../../Translations/i18nConfigs";
import AuthContext from "../../context/AuthContext";

const User = () => {
  const { accesstoken } = useContext(AuthContext);
  const pk = localStorage.getItem("pk");

  const [UsersData, setUsersData] = useState({
    first_name: "",
    last_name: "",
    business_name: "",
    email: "",
    mob_number: "",
  });
  const [Validations, setValidations] = useState([]);

  const [UserDetails, setUserDetails] = useState([]);
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    const User = (e) => {
      axios
        .get(`${configs.base_url}${configs.api_url}userdetail/`, {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        })
        .then((response) => {
          setUserDetails(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    User();
  }, [accesstoken]);

  const [selectedFile, setSelectedFile] = useState();
  const [loadingforcreate, setloadingforcreate] = useState(false);
  const formData = new FormData();
  formData.append(
    "first_name",
    UsersData.first_name ? UsersData.first_name : UserDetails.first_name
  );
  formData.append(
    "last_name",
    UsersData.last_name ? UsersData.last_name : UserDetails.last_name
  );
  formData.append(
    "business_name",
    UsersData.business_name
      ? UsersData.business_name
      : UserDetails.business_name
  );
  formData.append("email", UsersData.email);
  formData.append("mob_number", UsersData.mob_number);
  selectedFile &&
    formData.append("profile_photo", selectedFile, selectedFile.name);
  console.log(formData);
  const EditUser = (e) => {
    e.preventDefault();
    setloadingforcreate(true);
    axios
      .put(`${configs.base_url}${configs.api_url}userdetail/`, formData, {
        headers: {
          Authorization: "Bearer " + accesstoken,
        },
      })
      .then((response) => {
        setUserDetails(response.data);
        setloadingforcreate(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setValidations(error.response.data);
        setShowPopover(true);
        setloadingforcreate(false);
      });
  };
  console.log(UsersData);
  return (
    <IonApp>
      <IonContent>
        <Navigation />
        <IonGrid>
          <IonRow>
            <IonCol className="pagetitle">
              <h3>{i18n.t("users.title")}</h3>
            </IonCol>
          </IonRow>
          <form className="contentgrid">
            <IonRow>
              <IonCol className="picturecol">
                <div className="imageupload">
                  <label htmlFor="file-input">
                    <div className="imagecontainer">
                      {selectedFile ? (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                        />
                      ) : (
                        <img src={UserDetails.profile_photo} alt="Default" />
                      )}
                      <IonIcon
                        style={{ position: "absolute" }}
                        icon={cameraOutline}
                      ></IonIcon>
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/jpeg, image/png, image/svg"
                    onChange={(event) => {
                      setSelectedFile(event.target.files[0]);
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>
                      <h3>{i18n.t("users.firstName")}</h3>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      className="txtcolinput"
                      value={
                        UsersData && UsersData.first_name
                          ? UsersData.first_name
                          : UserDetails.first_name
                      }
                      onIonChange={(event) =>
                        setUsersData({
                          ...UsersData,
                          first_name: event.target.value,
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("users.lastName")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      className="txtcolinput"
                      value={
                        UsersData && UsersData.last_name
                          ? UsersData.last_name
                          : UserDetails.last_name
                      }
                      onIonChange={(event) =>
                        setUsersData({
                          ...UsersData,
                          last_name: event.target.value,
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("users.businessName")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      placeholder="Enter Your Company Name"
                      className="txtcolinput"
                      value={
                        UsersData && UsersData.business_name
                          ? UsersData.business_name
                          : UserDetails.business_name
                      }
                      onIonChange={(event) =>
                        setUsersData({
                          ...UsersData,
                          business_name: event.target.value,
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("users.email")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      placeholder="Enter Your Company Name"
                      className="txtcolinput"
                      value={
                        UsersData && UsersData.email
                          ? UsersData.email
                          : UserDetails.email
                      }
                      onIonChange={(event) =>
                        setUsersData({
                          ...UsersData,
                          email: event.target.value,
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("users.mobileNumber")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      placeholder="Enter Your Company Name"
                      className="txtcolinput"
                      value={
                        UsersData && UsersData.mob_number
                          ? UsersData.mob_number
                          : UserDetails.mob_number
                      }
                      onIonChange={(event) =>
                        setUsersData({
                          ...UsersData,
                          mob_number: event.target.value,
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>{i18n.t("users.groupName")}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      placeholder="Enter Your Company Name"
                      className="txtcolinput"
                      value={UserDetails.groups}
                      disabled={
                        UserDetails.groups !== "" && UserDetails.groups !== null
                          ? true
                          : false
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                {loadingforcreate ? (
                  <IonButton expand="block" onClick={EditUser}>
                    <IonSpinner />
                  </IonButton>
                ) : (
                  <IonButton expand="block" onClick={EditUser}>
                    {i18n.t("users.save")}
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
            <IonPopover
              className="confirmationboxss"
              isOpen={showPopover}
              onDidDismiss={() => setShowPopover(false)}
            >
              {Validations &&
                Object.keys(Validations).map((fieldName, index) => (
                  <p className="confirmationmessagess" key={index.toString()}>
                    {`${fieldName}: ${Validations[fieldName].join(", ")}`}
                  </p>
                ))}
            </IonPopover>
          </form>
        </IonGrid>
      </IonContent>
    </IonApp>
  );
};
export default User;
