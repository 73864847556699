import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSearchbar,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonSpinner,
  IonApp,
} from "@ionic/react";
import Navigation from "../../../components/Navigation";
import i18n from "../../../Translations/i18nConfigs";
import "./pPerformances.css";
import { downloadOutline } from "ionicons/icons";
import configs from "../../../configs";
import AuthContext from "../../../context/AuthContext";
import Pagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";

const ProductPerformance = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchProducts, setsearchProducts] = useState("");
  const [spinner, setSpinner] = useState(false);
  const { accesstoken } = useContext(AuthContext);

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const [selected, setSelected] = useState("meat");

  const handleButtonClick = (value) => {
    setSelected(value);
  };

  const [Orders, setOrders] = useState([]);
  const [Categories, setCategories] = useState([]);
  //pagiantion
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //TotalPage computation
  const totalPages = totalRecords < 5 ? 1 : Math.ceil(totalRecords / 5);

  useEffect(() => {
    const OrdersList = async () => {
      setSpinner(true);
      return axios
        .get(
          `${configs.base_url}${configs.api_url}products/category-most-sold/?search=${searchProducts}&page=${currentPage}&page_size=4`,
          {
            headers: {
              authorization: "Bearer " + accesstoken,
            },
            params: {
              category: selected,
            },
          }
        )

        .then((response) => {
          const reponseResult = response?.data.results;
          setOrders(reponseResult);
          console.log(response);
          setTotalRecords(response.data.count);
          setSpinner(false);
        });
    };
    const Categories = async () => {
      return axios
        .get(`${configs.base_url}${configs.api_url}category/list/`, {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        })

        .then((response) => {
          // console.log(response?.data);
          setCategories(response?.data);
        });
    };
    OrdersList();
    Categories();
  }, [selected, searchProducts]);

  function CSVDownload() {
    fetch(
      `${configs.base_url}${configs.api_url}products/all-product-transaction-list/`,
      {
        headers: {
          authorization: "Bearer " + accesstoken,
        },
        withCredentials: true,
      }
    )
      .then((response) => {
        if (response.headers.get("content-type") === "text/csv") {
          return response.blob();
        } else {
          console.log(response);
          throw new Error("Invalid response");
        }
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Produkt_Leistung.csv";
        a.click();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div>
      <div className="pagecontentinside">
        <IonGrid>
          <IonRow>
            <IonCol className="PPPagetitle">
              <h3>{i18n.t("performance.title")}</h3>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="searchordercol">
              <IonSearchbar
                placeholder={i18n.t("performance.searchProduct")}
                className="searchbarorder"
                value={searchProducts}
                onIonChange={(e) => setsearchProducts(e.detail.value)}
              ></IonSearchbar>
            </IonCol>
          </IonRow>

          <IonRow className="actionslistrowproductperformance">
            <div className="filterlistproductperformance">
              {Categories?.map((data, index) => (
                <IonCol key={index}>
                  <IonButton>{data.name}</IonButton>
                </IonCol>
              ))}
            </div>

            <IonCol className="PPExportDataCol">
              <IonButton onClick={CSVDownload} className="PPExportData">
                <IonIcon
                  icon={downloadOutline}
                  style={{ marginRight: "10px" }}
                />
                {i18n.t("performance.exportdata")}
              </IonButton>
            </IonCol>
          </IonRow>
          {!spinner && (
            <IonRow>
              <IonCol className="cardinfocol">
                {Orders?.map((item, index) => (
                  <IonCard key={index.toString()} className="marginpaddingzero">
                    <IonCardContent className="infoinsidecards">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12" size-md="6" size-lg="4" size-xl="3">
                            <h4 className="ion-text-center">
                              <strong>
                                {i18n.t("performance.productname")} {item.name}{" "}
                              </strong>
                            </h4>
                          </IonCol>

                          <IonCol size="12" size-md="6" size-lg="4" size-xl="1">
                            <h4 className="ion-text-center">
                              <strong>#{item.product_id}</strong>
                            </h4>
                          </IonCol>
                          <IonCol size="12" size-md="6" size-lg="4" size-xl="3">
                            <h4 className="ion-text-center">
                              {i18n.t("performance.unit")}{" "}
                              {item.total_units_sold}
                            </h4>
                          </IonCol>

                          <IonCol size="12" size-md="6" size-lg="6" size-xl="2">
                            <h4 className="ion-text-center">
                              {i18n.t("performance.salesconfirmed")}€{" "}
                              {(item.price * item.total_units_sold).toFixed(2)}
                            </h4>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-md="12"
                            size-lg="6"
                            size-xl="3"
                            style={{ textAlign: "center" }}
                          >
                            <IonButton className="categoryppbtn">
                              {item.category.name}
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                ))}
              </IonCol>
            </IonRow>
          )}
          {spinner && (
            <IonRow>
              <IonCol className="cardinfocol">
                <IonCard className="marginpaddingzero">
                  <IonCardContent className="infoinsidecards">
                    <IonGrid>
                      <IonRow>
                        <IonCol className="confirmbtn">
                          <IonSpinner></IonSpinner>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol size-lg="2" className="pagination">
              <Pagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ProductPerformance;
